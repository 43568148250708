import { initializeApp } from 'firebase/app'
import { getAuth, updatePassword } from 'firebase/auth'
import { getDatabase, ref, onValue } from 'firebase/database'
import { getFirestore, doc, setDoc, collection, getDocs } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
const firebaseConfig = {
  apiKey: 'AIzaSyCw1ETEQRyB_S1mI9GMpOO3oxJ4-zgIt68',
  authDomain: 'vote-hub-e6e15.firebaseapp.com',
  databaseURL: 'https://vote-hub-e6e15-default-rtdb.firebaseio.com',
  projectId: 'vote-hub-e6e15',
  storageBucket: 'vote-hub-e6e15.appspot.com',
  messagingSenderId: '651083879643',
  appId: '1:651083879643:web:c22e4c7f14011c3ac6e8fe',
  measurementId: 'G-0769YSKHQ2',
  projectId: 'vote-hub-e6e15',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const database = getDatabase(app)
const firestore = getFirestore(app)
const storage = getStorage(app)
const projectId = firebaseConfig.projectId
export { app, auth, database, firestore, storage, updatePassword, projectId }
